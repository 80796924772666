var supportsOrientationChange = 'onorientationchange' in window, orientationEvent = supportsOrientationChange ? 'orientationchange' : 'resize',
	htmlTimer,
	device_type,
	isTouch = (('ontouchstart' in window) || (navigator.msMaxTouchPoints > 0)),
	language = document.documentElement.lang.substring(0, 2);

if(/(up.browser|up.link|mmp|symbian|smartphone|midp|wap|phone|android|iemobile|w3c|acs\-|alav|alca|amoi|audi|avan|benq|bird|blac|blaz|brew|cell|cldc|cmd\-|dang|doco|eric|hipt|inno|ipaq|java|jigs|kddi|keji|leno|lg\-c|lg\-d|lg\-g|lge\-|maui|maxo|midp|mits|mmef|mobi|mot\-|moto|mwbp|nec\-|newt|noki|palm|pana|pant|phil|play|port|prox|qwap|sage|sams|sany|sch\-|sec\-|send|seri|sgh\-|shar|sie\-|siem|smal|smar|sony|sph\-|symb|t\-mo|teli|tim\-|tosh|tsm\-|upg1|upsi|vk\-v|voda|wap\-|wapa|wapi|wapp|wapr|webc|winw|winw|xda|xda\-) /i.test(navigator.userAgent)){
	if(/(tablet|ipad|playbook)|(android(?!.*(mobi|opera mini)))/i.test(navigator.userAgent)){
		device_type = 'tablet';
	} else {
		device_type = 'mobile';
	}
} else if(/(tablet|ipad|playbook)|(android(?!.*(mobi|opera mini)))/i.test(navigator.userAgent)){
	device_type = 'tablet';
} else {
	device_type = 'desktop';
}


if( isTouch == true ){
	$('html').addClass('touchevents');
} else {
	$('html').addClass('no-touchevents');
}


if( $('html').hasClass('homepage') )
{
	$('html').css('--vh', window.innerHeight/100 + 'px');
	$('html').css('--vw', window.innerWidth/100 + 'px');

	$(window).on('resize', function(){
		clearTimeout(htmlTimer);
		htmlTimer = setTimeout(function(){
			$('html').css({
				'--vh': window.innerHeight/100 + 'px',
				'--vw': window.innerWidth/100 + 'px',
			});
		}, 50);
	});

	$(window).on('load', function(){
		$('html').addClass('app-init');
	});
}

// Loader
function Loader() {
	this.htmlEl = document.documentElement;
	this.bodyEl = document.body;
	$(this.bodyEl).append('<div class="loader js-loader is-hidden"></div>');
	this.$element = $('.js-loader');
	this.show = function() {
		this.htmlEl.style.overflow = 'hidden';
		this.$element.removeClass('is-hidden');
	};
	this.hide = function() {
		this.htmlEl.style.overflow = '';
		this.$element.addClass('is-hidden');
	};
}
window.loader = new Loader();

// Aside menu
$('.js-toggle__menu').on('click', function(){
	$('html').removeClass('login-open search-open').toggleClass('menu-open');
	$('.main-nav__item').removeClass('main-nav__item--active');
	$('.sub-nav__item').removeClass('sub-nav__item--active');
});

$('.js-toggle__search').on('click', function(){
	$('html').removeClass('menu-open login-open').toggleClass('search-open');
	$('.main-nav__item').removeClass('main-nav__item--active');
	$('.sub-nav__item').removeClass('sub-nav__item--active');
});

$('.js-toggle__login').on('click', function(){
	$('html').removeClass('menu-open search-open').toggleClass('login-open');
	$('.main-nav__item').removeClass('main-nav__item--active');
	$('.sub-nav__item').removeClass('sub-nav__item--active');
});

$(document).on('keydown', function(e){
	if( e.which === 27 ){
		$('html').removeClass('menu-open search-open login-open industry--active');
		$('.main-nav__item').removeClass('main-nav__item--active');
		$('.sub-nav__item').removeClass('sub-nav__item--active');
		$('.industry').removeClass('industry--open');
	}
});

$(document).on('click', function(e){
	if(e.target.className == 'aside-dropdown aside-dropdown--menu') {
		$('html').removeClass('menu-open');
	}
});

$('.js-toggle__main-nav-dropdown').on('click', function(){
	if( !$(this).parents('.main-nav__item').hasClass('main-nav__item--active') ){
		$('.main-nav__item').removeClass('main-nav__item--active');
		$('.main-nav').addClass('main-nav--expanded');
		$(this).parents('.main-nav__item').addClass('main-nav__item--active');
	} else {
		$(this).parents('.main-nav__item').removeClass('main-nav__item--active');
	}
	$('.sub-nav__item').removeClass('sub-nav__item--active');
});

$('.js-toggle__sub-nav-dropdown').on('click', function(){
	if( !$(this).parents('.sub-nav__item').hasClass('sub-nav__item--active') ){
		$('.sub-nav__item').removeClass('sub-nav__item--active');
		$(this).parents('.sub-nav__item').addClass('sub-nav__item--active');
	} else {
		$(this).parents('.sub-nav__item').removeClass('sub-nav__item--active');
	}
});

$('.js-close__main-nav-dropdown').on('click', function(){
	$(this).parents('.main-nav__item').removeClass('main-nav__item--active');
});

$('.js-close__sub-nav-dropdown').on('click', function(){
	$(this).parents('.sub-nav__item').removeClass('sub-nav__item--active');
});


// Presentation
if( $('.presentation-holder').length > 0 ){
	$('.presentation-holder').each(function(i, e){

		var wheel_flag = false;

		var presentation_slider = $('.presentation', e).on('init', function(event, slick, currentSlide){

			$('.presentation-count__item--current', e).html(Number(slick.currentSlide + 1));
			$('.presentation-count__item--total', e).html(slick.slideCount);

			$('.slick-current .presentation-item').addClass('presentation-item--animating presentation-item--animated');

			$('html').attr('data-slide', $('.slick-current .presentation-item').data('slide'));

		}).on('beforeChange', function(event, slick, currentSlide){

			if( !$('html').hasClass('presentation-init') ){
				$('html').addClass('presentation-init');
			}

			$('html').removeAttr('data-slide');

		}).on('afterChange', function(event, slick, currentSlide){

			$('.presentation-count__item--current', e).html(Number(currentSlide + 1));

			$('.presentation-item').removeClass('presentation-item--animating').removeClass('presentation-item--animated');
			$('.slick-slide[data-slick-index='+ currentSlide +'] .presentation-item').addClass('presentation-item--animating');

			$('html').attr('data-slide', $('.slick-current .presentation-item').data('slide'));

			$('.industry').removeClass('industry--open');
			$('html').removeClass('industry--active');

			setTimeout(function(){
				wheel_flag = false;
			}, 800);

		})
		/*.on('mousedown', function(){

			$('.slick-slider', e).addClass('slick-drag');

			$(this).one('mouseup', function(){

				$(this).off('mousemove');

				$('.slick-slider', e).removeClass('slick-drag');

			});

		})*/
		.slick({
			speed: 750,
			cssEase: 'cubic-bezier(0.215, 0.61, 0.355, 1)',
			prevArrow: '<button type="button" class="slick-arrow presentation-arrow presentation-arrow__prev"><svg><use xlink:href="#icon-arrow-thin-prev"></use></svg></button>',
    		nextArrow: '<button type="button" class="slick-arrow presentation-arrow presentation-arrow__next"><svg><use xlink:href="#icon-arrow-thin-next"></use></svg></button>',
			dots: false,
			draggable: false,
			touchThreshold: 100,
			responsive: [
				{
					breakpoint: 1024,
					settings: {
						arrows: false
					}
				},
				{
					breakpoint: 900,
					settings: {
						cssEase: 'ease',
						speed: 450,
						arrows: false
					}
				}
			]
		});

		presentation_slider.on('wheel', function(e){

			if( wheel_flag === true ){
				return;
			}

			wheel_flag = true;

			if( $('.industry').hasClass('industry--open') ) return;

			if( e.originalEvent.deltaY > 0){
				$(this).slick('slickNext');
			} else {
				$(this).slick('slickPrev');
			}
			e.preventDefault();
		});

		$(document).on('keydown', function(e){
			if( $('.industry').hasClass('industry--open') || wheel_flag === true ){
				return;
			} else {
				if( e.which === 37 ) presentation_slider.slick('slickPrev');
				if( e.which === 39 ) presentation_slider.slick('slickNext');
			}
		});

	});
};


// Industry
if( $('.industry').length > 0 )
{

	if( device_type === 'desktop' ){
		$('.industry__body').mCustomScrollbar({
			scrollInertia: 450,
			updateOnContentResize: true
		});
	};

	$(document.body).on('click', '.js-open__industry', function(){
		$('.industry').addClass('industry--open');
		$('html').addClass('industry--active');
	}).on('click', '.js-close__industry', function(){
		$('.industry').removeClass('industry--open');
		$('html').removeClass('industry--active');
	});
}

// Is element in viewport
$(document).find('.watch-element').isInViewport(function(status){
	if (status === 'entered'){
		$(this).addClass('watch-element--active');
	}
});

function watchForElement(){
	$('.watch-element').isInViewport(function(status){
		if (status === 'entered'){
			$(this).addClass('watch-element--active');
		}
	});
}

watchForElement();


// Search
if( $('.autocomplete-list').length > 0 )
{

	if( device_type === 'desktop' ){
		$('.autocomplete-list').mCustomScrollbar({
			scrollInertia: 450,
			updateOnContentResize: true
		});
	};
}


// Tabs
$('.js-tabs__select').on('change', function(){
	window.location.href = this.value;
});

// To do - use transform instead leftc
$('.tabs__list').each(function(i, tabs){

	if( $('.tabs__link--active', tabs).length ){
		$('.tabs__list-indicator', tabs).css({
			'left' : $('.tabs__link--active', tabs).position().left + $('.tabs__link--active span', tabs).position().left,
			'width' : $('.tabs__link--active span', tabs).width()
		});
	}

	$('.tabs__link', tabs).on('mouseenter', function(e){

		$('.tabs__list-indicator', tabs).css({
			'left' : $(this).position().left + $('span', this).position().left,
			'width' : $('span', this).width()
		});

	}).on('mouseleave', function(e){

		$('.tabs__list-indicator', tabs).css({
			'left' : $('.tabs__link--active', tabs).position().left + $('.tabs__link--active span', tabs).position().left,
			'width' : $('.tabs__link--active span', tabs).width()
		});

	});

});


// Positions
$('.js-filter__positions').on('change', function(){

	if( this.value != 'all' ){
		$('.positions-list__item').addClass('sr');
		$('.positions-list__item[data-type="'+ this.value +'"]').removeClass('sr');
	} else {
		$('.positions-list__item').removeClass('sr');
	}

}).trigger('change');


$(document.body).on('click', '.positions-list__header:not(.positions-list--expanded .positions-list__header)', function(){
	if( !$(this).parents('.positions-list__item').hasClass('positions-list__item--active') ){

		$('.positions-list__item').removeClass('positions-list__item--active');
		$('.positions-list__body').slideUp(300);

		$(this).parents('.positions-list__item').addClass('positions-list__item--active')
		$(this).parents('.positions-list__item').find('.positions-list__body').stop(true, true).slideDown(300);

	} else {
		$('.positions-list__item').removeClass('positions-list__item--active');
		$('.positions-list__body').stop(true, true).slideUp(300);
	}
});


// Floating labels
var floatingLabels = function(){
	$('.floating-label:not(.floating-label--select)').each(function(i, e){

		var input = $('.field-input', e),
			label = $('label', e);

		function checkInput(){
			var inputValueLength = input.val().length;

			if( inputValueLength > 0 ){
				$(e).addClass('filled');
			} else {
				$(e).removeClass('filled');
			}
		}

		checkInput();

		input.on('change', function(){
			checkInput();
		}).on('click', function(){
			input.addClass('focus');
		}).on('mouseleave', function(){
			input.removeClass('focus');
		});

	});

	$('.floating-label--select').each(function(j, k){

		var select = $('.field-select', k),
			label = $('label', k);

		function checkSelect(){
			var inputSelectLength = select.val();

			if( inputSelectLength != '' ){
				$(k).addClass('filled');
			} else {
				$(k).removeClass('filled');
			}
		}

		checkSelect();

		select.on('change', function(){
			checkSelect();
		});

	});
};

floatingLabels();


// Custom file upload
$(document).on('change', '.custom-file :file', function(e){

	var master = $(this).parents('.custom-file'),
		input = $(':file', master),
		text = $(':text', master),
		button = $('.custom-file__button', master),
		numFiles = input.get(0).files ? input.get(0).files.length : 1,
		label = input.val().replace(/\\/g, '/').replace(/.*\//, '');

	input.trigger('fileselect', [
		numFiles,
		label
	]);
});

$(document).on('fileselect', '.custom-file :file', function(event, numFiles, label){
	var custom = $(this).parents('.custom-file'),
		input = $(':text', custom),
		log = numFiles > 1 ? numFiles + ' files selected' : label

	if( input.length > 0 ){
		input.val(log);
	}
});


// Hero header
function heroParallax(scrolled){
	$('.hero-header__image').css('margin-top', Math.floor(scrolled * 0.45) +'px');
};

if( $('.hero-header').length > 0 ){

	$(window).on('scroll', function(){
		heroParallax(window.scrollY || document.documentElement.scrollTop);
	});

}


// Gallery slider
if( $('.gallery-slider__holder').length )
{
	$('.gallery-slider__holder').each(function(i, e){

		var gallery_slider = $('.gallery-slider', e).slick({
			speed: 900,
			arrows: true,
			prevArrow: '<button type="button" class="slick-arrow slick-arrow--prev"><svg><use xlink:href="#icon-prev"></use></svg></button>',
    		nextArrow: '<button type="button" class="slick-arrow slick-arrow--next"><svg><use xlink:href="#icon-next"></use></svg></button>',
			dots: false,
			centerMode: true,
			draggable: false,
			adaptiveHeight: true,
			slidesToShow: 4,
			slidesToScroll: 4,
			variableWidth: true,
			touchThreshold: 100,
			cssEase: 'cubic-bezier(0.86, 0, 0.07, 1)',
			appendArrows: $('.gallery-slider__controls', e),
			responsive: [
				{
					breakpoint: 1024,
					settings: {
						speed: 500,
						centerMode: false,
						variableWidth: false,
						slidesToShow: 3,
						slidesToScroll: 2,
						cssEase: 'ease'
					}
				},
				{
					breakpoint: 767,
					settings: {
						speed: 500,
						centerMode: false,
						variableWidth: false,
						slidesToShow: 2,
						slidesToScroll: 2,
						arrows: false,
						dots: true,
						cssEase: 'ease'
					}
				},
				{
					breakpoint: 560,
					settings: {
						speed: 500,
						centerMode: false,
						variableWidth: false,
						slidesToShow: 1,
						slidesToScroll: 1,
						arrows: false,
						dots: true,
						cssEase: 'ease'
					}
				}
			]
		});

		gallery_slider.on('wheel', function(e){
			if( e.originalEvent.deltaY > 0){
				$(this).slick('slickNext');
			} else {
				$(this).slick('slickPrev');
			}
			e.preventDefault();
		});
	});
}


// Gallery
if( $('.gallery-slider').length )
{
	$('.gallery-slider').each(function(i, slider){
		$(slider).lightGallery({
			selector: $('.gallery-slider__link', slider)
		});
	});
}


// History slider
if( $('.history-slider__holder').length )
{
	$('.history-slider__holder').each(function(i, e){
		var history_slider = $('.history-slider', e).slick({
			speed: 900,
			arrows: true,
			prevArrow: '<button type="button" class="slick-arrow slick-arrow--prev"><svg><use xlink:href="#icon-prev"></use></svg></button>',
    		nextArrow: '<button type="button" class="slick-arrow slick-arrow--next"><svg><use xlink:href="#icon-next"></use></svg></button>',
			dots: false,
			adaptiveHeight: true,
			touchThreshold: 100,
			cssEase: 'cubic-bezier(0.86, 0, 0.07, 1)',
			appendArrows: $('.history-slider__controls', e),
			responsive: [
				{
					breakpoint: 900,
					settings: {
						speed: 500,
						arrows: false,
						dots: true,
						cssEase: 'ease'
					}
				}
			]
		});

		history_slider.on('wheel', function(e){
			if( e.originalEvent.deltaY > 0){
				$(this).slick('slickNext');
			} else {
				$(this).slick('slickPrev');
			}
			e.preventDefault();
		});
	});
}


// Products slider
if( $('.products-slider__holder').length )
{
	$('.products-slider__holder').each(function(i, e){
		var products_slider = $('.products-slider', e).slick({
			speed: 900,
			arrows: false,
			dots: true,
			slidesToShow: 2,
			slidesToScroll: 2,
			adaptiveHeight: true,
			touchThreshold: 100,
			cssEase: 'cubic-bezier(0.86, 0, 0.07, 1)',
			appendArrows: $('.history-slider__controls', e),
			responsive: [
				{
					breakpoint: 620,
					settings: {
						speed: 500,
						slidesToShow: 1,
						slidesToScroll: 1,
						cssEase: 'ease'
					}
				}
			]
		});

		products_slider.on('wheel', function(e){
			if( e.originalEvent.deltaY > 0){
				$(this).slick('slickNext');
			} else {
				$(this).slick('slickPrev');
			}
			e.preventDefault();
		});
	});
}


// Effects list
function forceNumeric(){
    var $input = $(this);
    $input.val($input.val().replace(/[^\d]+/g, ''));
}
$(document).on('input', '.js-only__numbers', forceNumeric);

$('.option-input').each(function(i, e){

	if( $(':checkbox', e).prop('checked') == true ){
		$('input:not(:checkbox):not(:radio)', e).prop('disabled', false);
	}

	$(':checkbox', e).on('change', function(){
		$('input:not(:checkbox):not(:radio)', e).prop('disabled', !$(this).prop('checked'));
	});
})


// Modal
var _modal_background = '#000e23',
	_modal_opacity = '.38',
	_modal_follow_speed = 400;

$(document).on('click', '.products-list__preview a:not(.products-list__link)', function(e){

	e.preventDefault();

	$.ajax({
		type: 'GET',
		url: $(this).data('product'),
		dataType: 'html',
		success: function(data){

			$('.modal__content').html(data);

			var ajaxPopup = $('#ajax-popup').bPopup({
				followSpeed: _modal_follow_speed,
				modalColor: _modal_background,
				opacity: _modal_opacity,
				closeClass: 'modal__close'
			});

			ajaxPopup.reposition(1);

		}
	});​​​​​​​​​​​​​​​
});


// Counter
$(document).find('.counter').isInViewport(function(status){
	if (status === 'entered'){

		$('.counter').each(function(i, e){

			if( $(e).hasClass('counter--completed') ) return;

			$({ countNum: 0 }).animate({ countNum: $(e).data('count') }, {
				duration: $(e).data('duration'),
				easing: 'linear',
				step: function(){
					$(e).html(Math.floor(this.countNum) + '<span class="counter-suffix">'+ $(e).data('suffix') +'</span>');
				},
				complete: function(){
					$(e).html(Math.floor(this.countNum) + '<span class="counter-suffix">'+ $(e).data('suffix') +'</span>');
					$('.counter').addClass('counter--completed');
				}
			});
		});
	}
});


// Tooltip
if( $('.tippy').length > 0 ){

	$('.tippy').each(function(i, e){
		tippy(e, {
			content: $(e).attr('data-tip'),
			interactive: true,
			animateFill: false,
			arrow: true,
			trigger: 'mouseenter',
			delay: [0, 600],
			theme: 'rotoprint'
		})
	});
}


// Form group reveal
$('.js-form-group__reveal').on('click', function(){
	$('.form-group').removeClass('form-group--reveal');
	$(this).addClass('sr');
});


// System message
$('.system-message__close').on('click', function(){
	$('.system-message').addClass('system-message--hidden');
});


// Expand text
if( $('.expand-text').length ) {
	$('.expand-text').each(function(i, e){
		$('.js-expand__text', e).on('click', function(){

			if( !$(e).hasClass('expand-text--expanded') ) {
				$(e).addClass('expand-text--expanded');
				$(this).text( $(this).attr('data-less'));
			} else {
				$(e).removeClass('expand-text--expanded');
				$(this).text( $(this).attr('data-more'));
			}

			/*
			$(this).text(function(i, text){
				return text === $(this).attr('data-more') ? $(this).attr('data-less') : $(this).attr('data-more');
			});

			$(e).toggleClass('expand-text--expanded');
			*/
		});
	});
}


// Parsley validators
window.Parsley.addValidator('filemaxmegabytes', {

	requirementType: 'string',
	validateString: function (value, requirement, parsleyInstance) {

		var file = parsleyInstance.$element[0].files;
		var maxBytes = requirement * 1048576;

		if (file.length == 0) {
			return true;
		}

		setTimeout(function(){
			if( parsleyInstance.$element[0].classList.contains('parsley-error') ){

				var master = $(parsleyInstance.$element[0]).parents('.floating-label');
				$(parsleyInstance.$element[0]).val('').blur();
				$(parsleyInstance.$element[0]).next('input').val('').blur();
				master.removeClass('filled');
			}
		}, 1);

		return file.length === 1 && file[0].size <= maxBytes;

	}

}).addValidator('filemimetypes', {

	requirementType: 'string',
	validateString: function (value, requirement, parsleyInstance) {

		var file = parsleyInstance.$element[0].files;

		if (file.length == 0) {
			return true;
		}

		var allowedMimeTypes = requirement.replace(/\s/g, "").split(',');

		setTimeout(function(){
			if( parsleyInstance.$element[0].classList.contains('parsley-error') ){

				var master = $(parsleyInstance.$element[0]).parents('.floating-label');
				$(parsleyInstance.$element[0]).val('').blur();
				$(parsleyInstance.$element[0]).next('input').val('').blur();
				master.removeClass('filled');
			}
		}, 1);

		return allowedMimeTypes.indexOf(file[0].type) !== -1;

	}
});

Parsley.setLocale(language);
$('form:not([data-skip-validator="true"])').parsley({
	excluded: '.form-group--reveal *'
});
if($('.js-header-text')){
	$('.js-header-text').each(function(i,text){
		checkHeaderHeight(text);
	})
	window.addEventListener('resize',function(){{
		$('.js-header-text').each(function(i,text){
			checkHeaderHeight(text);
		})
	}})
}
function checkHeaderHeight(el){
	var $headerText = $(el);
	$headerText.parents('.hero-header ').css({'margin-bottom': $headerText.height()/2});
}