/* ====================================================
 * jQuery Is In Viewport.
 * https://github.com/frontid/jQueryIsInViewport
 * Marcelo Iván Tosco (capynet)
 * Inspired on https://stackoverflow.com/a/40658647/1413049
 * ==================================================== */

var offset_viewport = 100;

if(/(up.browser|up.link|mmp|symbian|smartphone|midp|wap|phone|android|iemobile|w3c|acs\-|alav|alca|amoi|audi|avan|benq|bird|blac|blaz|brew|cell|cldc|cmd\-|dang|doco|eric|hipt|inno|ipaq|java|jigs|kddi|keji|leno|lg\-c|lg\-d|lg\-g|lge\-|maui|maxo|midp|mits|mmef|mobi|mot\-|moto|mwbp|nec\-|newt|noki|palm|pana|pant|phil|play|port|prox|qwap|sage|sams|sany|sch\-|sec\-|send|seri|sgh\-|shar|sie\-|siem|smal|smar|sony|sph\-|symb|t\-mo|teli|tim\-|tosh|tsm\-|upg1|upsi|vk\-v|voda|wap\-|wapa|wapi|wapp|wapr|webc|winw|winw|xda|xda\-) /i.test(navigator.userAgent)){
  offset_viewport = 25;
}


!function ($) {
    'use strict'
  
    var Class = function (el, cb) {
      this.$el = $(el);
      this.cb = cb;
      this.watch();
      return this;
    };
  
    Class.prototype = {
  
      /**
       * Checks if the element is in.
       *
       * @returns {boolean}
       */
      isIn: function isIn() {
        var _self = this;
        var $win = $(window);
        var elementTop = _self.$el.offset().top + offset_viewport;
        var elementBottom = elementTop + _self.$el.outerHeight();
        var viewportTop = $win.scrollTop();
        var viewportBottom = viewportTop + $win.height();
        return elementBottom > viewportTop && elementTop < viewportBottom;
      },
  
      /**
       * Launch a callback indicating when the element is in and when is out.
       */
      watch: function () {
        var _self = this;
        var _isIn = false;
  
        $(window).on('resize scroll touchstart', function () {
  
          if (_self.isIn() && _isIn === false) {
            _self.cb.call(_self.$el, 'entered');
            _isIn = true;
          }
  
          if (_isIn === true && !_self.isIn()) {
            _self.cb.call(_self.$el, 'leaved');
            _isIn = false;
          }
  
        }).trigger('scroll');
      }
  
  
    };
  
    // jQuery plugin.
    //-----------------------------------------------------------
    $.fn.isInViewport = function (cb) {
      return this.each(function () {
        var $element = $(this);
        var data = $element.data('isInViewport');
        if (!data) {
          $element.data('isInViewport', (new Class(this, cb)));
        }
      })
    }
  
  }(window.jQuery);